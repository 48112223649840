.navbar {
    background-color: #f2f2f2;
    position: absolute;
    border: 0;
    right: 0;
    left: 0;
    width: 100%;
    overflow: hidden !important;    
    padding: 10px 0px;
    display: flex;
    align-items: center;
    height: 41.5px;
    justify-content: center;
  }
  
  .navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .navbar li {
    margin-right: 10px;
    padding: 0px 10px;
  }
  
  .navbar li:last-child {
    margin-right: 0;
  }
  
  .navbar a {
    text-decoration: none;
    color: #333;
    padding: 5px;
  }
  
  .navbar a:hover {
    color: #000; 
  }
  .navbar a.activeLink{
    color: #0e1527;
    font-weight: 700;

  }